import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				Teknik Sorunlara Hızlı Çözümünüz
			</title>
			<meta name={"description"} content={"TechEase Solutions olarak her teknik sorunun bir çözümü olduğuna inanıyoruz."} />
			<meta property={"og:title"} content={"Teknik Sorunlara Hızlı Çözümünüz"} />
			<meta property={"og:description"} content={"TechEase Solutions olarak her teknik sorunun bir çözümü olduğuna inanıyoruz."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668bd0f48e2e8e00217debbc/images/device-repair.jpg?v=2024-07-08T13:23:10.870Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});